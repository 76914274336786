import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/pluralsight-flow-meta.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import PatternsIcon from '../../assets/images/product/blurb-icons/anti-patterns.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import ZoomIcon from '../../assets/images/product/blurb-icons/zoom.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/org-insights-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/investment-balance-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight3: file(
      relativePath: { eq: "images/mosaic/ci-insights-workflow-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/surveys-670x587.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const SwarmiaVsPSF = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Pluralsight Flow (GitPrime) vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Pluralsight Flow (GitPrime) and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Pluralsight Flow (GitPrime) vs.&nbsp;Swarmia</>}
        content="Pluralsight Flow (GitPrime) was a pioneer in surfacing first-generation Git analytics. But if you're looking for a healthier approach to driving engineering effectiveness, Swarmia may well be a better alternative."
        backgroundImage={getImage(data.backgroundImage)!}
        secondaryButton={null}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading="What do Swarmia and Pluralsight Flow have in common?"
        content="Both Swarmia and Pluralsight Flow give you access to engineering metrics and help software leaders gain visibility into their organization."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Engineering metrics"
            text="Track essential engineering metrics from GitHub and Jira."
            icon={PatternsIcon}
          />,
          <Blurb
            key="2"
            title="Insights for leaders"
            text="Gain visibility into the entire engineering organization."
            icon={ZoomIcon}
          />,
          <Blurb
            key="3"
            title="Enterprise ready"
            text="From team hierarchies to SSO integrations, both tools meet enterprise needs."
            icon={AllWorkIcon}
          />,
        ]}
      />
      <QuoteBlock person="maryanna" />
      <LeadBlock
        heading="Why modern engineering organizations choose Swarmia over Pluralsight Flow"
        content="Where Flow is a dashboard for engineering leaders, Swarmia is a comprehensive platform that helps you put your whole engineering organization on a path of continuous improvement."
      />
      <MosaicBlock
        title={<>Carefully curated, high-quality&nbsp;metrics</>}
        content={
          <>
            Like many analytics-heavy engineering productivity tools, Flow’s aim
            seems to be to surface as many metrics as humanly possible. The
            problem is, some of these metrics are nonsensical while others can
            create an unhealthy environment by tracking the activity of
            individual developers.
            <br />
            <br />
            We’ve consciously left out some of the harmful Git-based metrics
            from Swarmia, because we know that an engineering effectiveness
            platform will only help drive change if everyone in the organization
            — including software engineers — can trust the data.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Find the insights that&nbsp;matter&nbsp;for&nbsp;you"
        content={
          <>
            A common frustration among organizations using Flow is that it is
            time-consuming to find the right insights among all the noise,
            especially if you open the platform infrequently.
            <br />
            <br />
            Swarmia is designed so that everyone in your organization,
            regardless of their role or how frequently they use the platform,
            can easily find what they’re looking for.
          </>
        }
        image={getImage(data.mosaicRight3)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Combine system metrics with&nbsp;survey data</>}
        content={
          <>
            At Swarmia, we know that system metrics are only one part of the
            puzzle. And while healthy engineering metrics can help you answer
            some questions, they rarely offer concrete ideas for improvement.
            <br />
            <br />
            That’s why Swarmia helps you correlate engineering metrics with
            sentiment data from developer experience surveys. By combining this
            data, you’ll be able to not only identify problems but also solve
            them.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
      />
      <MosaicBlock
        title="Superior data export capabilities"
        content={
          <>
            A recurring piece of feedback we hear from former Pluralsight Flow
            users is that the tool doesn’t make it easy to export data to
            external dashboards.
            <br />
            <br />
            At Swarmia, we offer various alternatives for exporting data because
            we understand the value of combining data from multiple sources.
            This way, you can build custom reports and dashboards that bring
            together all the data that’s important to your business.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <QuoteBlock person="pau" />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: enterprise-grade features with flexible self-service configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to export APIs, Swarmia fits the needs of large organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and roll out."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title={
            <>
              Start building a more effective engineering&nbsp;organization
              today.
            </>
          }
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/bringing-visibility-into-a-developers-work/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default SwarmiaVsPSF
